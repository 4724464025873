import { createGtm, useGtm } from '@gtm-support/vue-gtm';
import type { GtmSupport, VueGtmUseOptions } from '@gtm-support/vue-gtm';

type PaymentType = 'ApplePay' | 'GooglePay' | 'Card Payment' | 'PayPal';

let gtm: GtmSupport;

const init = (app: ToDo, opts?: VueGtmUseOptions) => {
  if (window.Cypress || import.meta.env.VITE_APP_IS_IN_E2E == 'true') {
    gtm = {
      trackEvent: () => {},
    };
    return;
  }

  app.use(
    createGtm({
      id: import.meta.env.VITE_GTM_ACCOUNT_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: false, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
      queryParams: {
        gtm_auth: import.meta.env.VITE_GTM_AUTH_PARAM,
        gtm_preview: 'env-7',
        gtm_cookies_win: 'x',
      },
      ...opts,
    }),
  );

  gtm = useGtm()!;
};

const doPlaceOrderGtmEvent = ({ paymentType }: { paymentType: PaymentType }) => {
  trackJourneyEvent({
    event: 'payment_complete',
    payment_type: paymentType,
    label: 'Payment has been processed',
  });
};

/**
 * Custom events that indicate the user has reached a certain point in a checkout journey flow.
 */
export const trackJourneyEvent = (args: { event: string }) => {
  return trackEvent({
    ...args,
    category: 'User Journey',
  });
};

export const trackEvent = (args: { event: string }) => {
  if (!args.event) {
    throw new Error('Invalid Google Tag event!');
  }

  return gtm.trackEvent(args);
};

export default {
  init,
  trackEvent,
  trackJourneyEvent,
  doPlaceOrderGtmEvent,
};
