<template>
  <button
    type="button"
    class="button button--primary button--xs sticky-product-summary__pay-now-btn"
    :class="{
      'sticky-product-summary__pay-now-btn--visible': isVisible,
    }"
    @click="scrollToPaymentButtons"
  >
    Buy now
  </button>
</template>

<script>
import { polyfill, elementScrollIntoView } from 'seamless-scroll-polyfill';

export default {
  name: 'AccordionBuyNowCta',
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    scrollToPaymentButtons() {
      polyfill();
      elementScrollIntoView(document.getElementById('app'), { behavior: 'smooth' });
    },
  },
};
</script>
