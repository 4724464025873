<template>
  <footer class="footer text-align-center">
    <p v-if="advertiserFooter" v-text="advertiserFooter" class="secondary-text mb-0" />

    <p class="secondary-text">
      By placing an order you are agreeing to the
      <a
        type="button"
        @click="showTermsAndConditions"
        style="text-decoration: underline; cursor: pointer"
        >Terms and Conditions</a
      >
      of {{ companyName }}.
    </p>

    <div class="footer__links">
      <Copyright />
      <SecureShopping />
    </div>
  </footer>
</template>

<script>
import { mapState, mapWritableState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { polyfill, elementScrollIntoView } from 'seamless-scroll-polyfill';
import SecureShopping from '@/components/Footer/SecureShopping/index.vue';
import Copyright from '@/components/Footer/Copyright/index.vue';

export default {
  name: 'Footer',
  components: {
    SecureShopping,
    Copyright,
  },
  computed: {
    ...mapState(useAdvertiserStore, ['companyName', 'advertiserFooter']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),
  },
  methods: {
    showTermsAndConditions() {
      polyfill();

      const element = document.getElementById('terms-tab');

      this.currentlyOpenInfoTab = 'TERMS_AND_CONDITIONS';

      if (element) {
        return elementScrollIntoView(element, { behavior: 'smooth' });
      }
    },
  },
};
</script>
