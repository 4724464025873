import { defineStore } from 'pinia';
import { useCartStore } from '@/stores/CartStore';
import { useProductStore } from '@/stores/ProductStore';
import { Sentry } from '@/helpers/moduleMockWrappers/sentry';
import { getRouter } from '@/router';
import constants from '@/constants';
import { OutOfStockError } from '@/types/errors.types';

enum PaymentButtonEnum {
  'google',
  'hostedFields',
  'apple',
  'paypal',
}

type PaymentButtonType = keyof typeof PaymentButtonEnum;
type PaymentButtonInitialisationStateType = null | 'NOT_ENABLED' | 'NOT_SUPPORTED' | 'INITIALISED';

type State = {
  code: string | null;
  parentDomain: string | null;
  loading: {
    app: boolean;
    logo: boolean;
  };

  initialisedPaymentButtons: Record<PaymentButtonType, PaymentButtonInitialisationStateType>;

  isPaymentButtonsInitialised: boolean;
  currentlyOpenInfoTab:
    | 'PRODUCT_INFO'
    | 'PRODUCT_DELIVERY'
    | 'PRODUCT_DETAILS'
    | 'PRODUCT_FEATURES'
    | 'TERMS_AND_CONDITIONS';
  fallbackUrl: string | null;
  isPreviewMode: boolean;
};

const isApplePaySupported = () => {
  let windowApiIsCompatible = false;

  try {
    windowApiIsCompatible = !!(
      window.ApplePaySession &&
      window.ApplePaySession.supportsVersion(3) &&
      window.ApplePaySession.canMakePayments()
    );
  } catch (err) {
    console.info(
      'Unable to check for Apple Pay support due to browser security profile, likely an incompatible version. ApplePay will not be offered.',
    );

    Sentry.captureException(err);
  }

  return windowApiIsCompatible;
};

const getInitialState = (): State => ({
  code: null,
  parentDomain: null,
  loading: {
    app: true,
    logo: false,
  },
  supports: {
    paymentRequest: typeof window.PaymentRequest === 'function',
    payPal: true,
    applePay: isApplePaySupported(),
  },

  // Just until we can get it from the server - it should really be null for now DS 31/1/24
  // Then @todo - wait until all required initial things are loaded on launch and handle errors accordingly - DS.
  currency: {
    currencyCode: 'GBP',
    symbol: '£',
  },
  // Keeps a record of what XHR requests are in progress so we can present loading indication accordingly
  xhrTracker: {
    blocksUI: [],
  },

  initialisedPaymentButtons: {
    hostedFields: 'INITIALISED',
    google: null,
    apple: isApplePaySupported() ? null : 'NOT_SUPPORTED',
    paypal: null,
  },

  isPaymentButtonsInitialised: false,
  currentlyOpenInfoTab: 'PRODUCT_DETAILS',
  fallbackUrl: null,
  isPreviewMode: false,
});

export const useMainStore = defineStore('mainStore', {
  state: (): State => {
    return getInitialState();
  },
  getters: {
    isShopFrontInitialised: () => {
      const cartStore = useCartStore();
      const productStore = useProductStore();

      return cartStore.isCartInitialised && productStore.isProductsInitialised;
    },
    isShowPricingElements: () => {
      const cartStore = useCartStore();
      const productStore = useProductStore();

      return (
        (cartStore.cart?.pricing && !productStore.isProductHasVariants) ||
        cartStore.getIsProductOptionsSelectionValid
      );
    },
  },
  actions: {
    async setData({
      code,
      parentDomain,
      isPreviewMode,
    }: {
      code: string;
      parentDomain: string;
      isPreviewMode: boolean;
    }) {
      try {
        this.code = code;
        this.parentDomain = parentDomain;
        this.isPreviewMode = isPreviewMode;

        // Stores
        const productStore = useProductStore();
        const cartStore = useCartStore();

        // Fetch static Product data and set stores
        await productStore.fetchProductData(code);

        // TODO? document.title = this.product.name + " | " + this.advertiser.name;

        this.loading.app = false;
        this.loading.logo = true;

        setTimeout(() => {
          this.loading.logo = false;
        }, 2000);

        // Set product base Price and Availability
        await productStore.fetchProductPricingAndAvailability(code);

        // Check for stock availability
        const showStockUnavailabilityMessage = () =>
          this.goToErrorPage({
            error: new OutOfStockError(),
          });

        if (productStore.isProductHasVariants) {
          if (!productStore.getIsAnyProductVariantAvailable()) {
            showStockUnavailabilityMessage();
          }
        } else {
          if (!cartStore.getIsSelectedProductAvailable) {
            showStockUnavailabilityMessage();
          }
        }

        // Initialise Cart
        await cartStore.init(code);

        // Set Currency
        // TODO this.currency = currency;
      } catch (error) {
        this.goToErrorPage({ error });
      }
    },

    /**
     * Load Assets
     * @todo - this was taken from prototype. Look into a better way of doing this
     */
    loadJsAssets() {
      // Loquate (Address lookup)
      (function (n, t, i, r) {
        var u, f;
        (n[i] = n[i] || {}),
          (n[i].initial = {
            accountCode: 'SMART11286',
            host: 'SMART11286.pcapredict.com',
          }),
          (n[i].on =
            n[i].on ||
            function () {
              (n[i].onq = n[i].onq || []).push(arguments);
            }),
          (u = t.createElement('script')),
          (u.async = !0),
          (u.src = r),
          (f = t.getElementsByTagName('script')[0]),
          f.parentNode.insertBefore(u, f);
      })(window, document, 'pca', '//SMART11286.pcapredict.com/js/sensor.js');
    },

    closeCheckout() {
      parent.window.postMessage('closeCheckout', '*');
    },

    previousStepInCheckout(router) {
      const checkoutSteps = [
        {
          name: 'Product',
          path: '',
        },
        {
          name: 'Shipping',
          path: '/shipping',
        },
        {
          name: 'Checkout',
          path: '/checkout',
        },
      ];

      const currentStepIndex = checkoutSteps.findIndex((step) => {
        return step.name === router.currentRoute.value.name;
      });

      const previousStepIndex = currentStepIndex ? currentStepIndex - 1 : 0;

      this.redirectToView(`${checkoutSteps[previousStepIndex].path}`, router.push);
    },

    redirectToView(path: string, redirectFn: Function) {
      redirectFn(`${path}/${this.code}`);
    },

    async goToErrorPage({ error, isSkipLogToSentry = false }) {
      const router = await getRouter();

      this.endAppLoading();

      this.redirectToView('/error', (path: string) => {
        router.replace({
          path,
          query: {
            error: JSON.stringify(error),
            isSkipLogToSentry,
            shortCode: this.code,
          },
        });
      });
    },

    startAppLoading() {
      this.loading.app = true;
    },

    endAppLoading() {
      this.loading.app = false;
    },

    xhrTracker: {
      blocksUI: [],
    },

    addToXhrTracker(id, type) {
      if (!this.xhrTracker[type].includes(id)) {
        this.xhrTracker[type].push(id);
      }
    },

    removeFromXhrTracker(id, type) {
      const index = this.xhrTracker[type].indexOf(id);
      this.xhrTracker[type] = this.xhrTracker[type].splice(index, 1);
    },

    clearXhrTracker() {
      this.xhrTracker = getInitialState().xhrTracker;
    },

    /**
     * @param isError {boolean} indicates that initialisation failed
     */
    setPaymentButtonInitialised(
      btnFlag: PaymentButtonType,
      initState: PaymentButtonInitialisationStateType,
    ) {
      this.initialisedPaymentButtons[btnFlag as PaymentButtonType] = initState;

      // Set the flag in state to indicate whether all buttons are initialised
      this.isPaymentButtonsInitialised = this.checkIsPaymentButtonsInitialised();
    },

    checkIsPaymentButtonsInitialised(isTimeoutRoutine = false) {
      return Object.entries(this.initialisedPaymentButtons).every(([btnFlag, val]) => {
        const isInitialisationAttemptComplete = val !== null;

        if (!isInitialisationAttemptComplete && isTimeoutRoutine) {
          const errMsg = `Payment method '${btnFlag}' failed to initialise successfully in alotted timeout of ${constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT}ms`;
          console.error(errMsg);
          Sentry.captureException(errMsg);
        }

        return isInitialisationAttemptComplete;
      });
    },

    /**
     * After a period of time call this function to asses which payment buttons have not yet initialised successfully.
     * Logs an error for each one that hasn't yet.
     * You can then check the value of this.initialisedPaymentButtons to conditionally render out only the buttons that have
     * initialised successfully at this time.
     */
    assessAvailablePaymentButtonsAfterInitialisationAttempted() {
      if (this.isPaymentButtonsInitialised) {
        return;
      }

      this.checkIsPaymentButtonsInitialised(true);

      // Now free up the UI to conditionally render just the buttons that initialised successfully.
      this.isPaymentButtonsInitialised = true;
    },
  },
});
