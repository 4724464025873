<template>
  <div id="payment-buttons-container">
    <div v-show="isPaymentButtonsInitialised" data-test="payment-buttons-is-initialised-wrapper">
      <div v-if="transaction.token" data-test="payment-buttons-is-token-set-wrapper">
        <div
          v-if="paymentProvider === 'braintree'"
          data-test="payment-buttons-is-braintree-wrapper"
        >
          <BraintreeApplePay
            v-if="supports.applePay"
            v-show="isPaymentButtonInitialisedSuccessfully('apple')"
            data-test="pay-btn-apple-pay"
          />
          <BraintreeGooglePay
            :googleApi="googleApi"
            :braintreeApi="braintreeApi"
            v-show="isPaymentButtonInitialisedSuccessfully('google')"
          />
          <BraintreePaypal
            :paypalApi="paypalApi"
            :braintreeApi="braintreeApi"
            v-show="isPaymentButtonInitialisedSuccessfully('paypal')"
          />
        </div>
      </div>

      <div v-if="isPaymentButtonInitialisedSuccessfully('hostedFields')">
        <button
          aria-label="Credit or Debit Card"
          class="button button--primary button--full-width card-payment border-rounded-default"
          @click="progressToCheckout()"
          type="button"
          data-test="hosted-fields-payment-btn"
        >
          Pay with Debit or Credit Card
        </button>
        <div class="payment-icons">
          <IconVisa />
          <IconMastercard />
          <IconAmericanExpress />
          <IconMaestro />
        </div>
      </div>
    </div>

    <div v-show="!isPaymentButtonsInitialised" class="loading-message-container">
      <lottie-animation :animationData="loaderAnimation" :loop="true" />

      <span>Loading payment options...</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { usePublisherStore } from '@/stores/PublisherStore.js';

import BraintreeGooglePay from './Braintree/BraintreeGooglePay/index.vue';
import BraintreeApplePay from './Braintree/BraintreeApplePay/index.vue';
import BraintreePaypal from './Braintree/BraintreePaypal/index.vue';

import loader from '@/assets/loader.json';
import IconVisa from '@/components/icons/IconVisa.vue';
import IconAmericanExpress from '@/components/icons/IconAmericanExpress.vue';
import IconMastercard from '@/components/icons/IconMastercard.vue';
import IconMaestro from '@/components/icons/IconMaestro.vue';

import { getRouter } from '@/router';
import constants from '@/constants';

export default {
  name: 'PaymentComponent',
  components: {
    IconVisa,
    BraintreeGooglePay,
    BraintreeApplePay,
    BraintreePaypal,
    IconAmericanExpress,
    IconMastercard,
    IconMaestro,
  },
  data() {
    return {
      open: false,
      googleApi: window.google,
      paypalApi: window.paypal,
      braintreeApi: window.braintree,
      paymentButtonInitialisationTimerId: null,
      paymentButtonInitialisationTimerExpired: false,
    };
  },
  mounted() {
    this.startPaymentButtonInitialisationTimer();
  },
  beforeUnmount() {
    if (this.paymentButtonInitialisationTimerId) {
      clearTimeout(this.paymentButtonInitialisationTimerId);
    }
  },
  computed: {
    ...mapState(useAdvertiserStore, ['companyName', 'advertiserTerms', 'paymentProvider']),
    ...mapState(usePaymentStore, ['transaction']),
    ...mapState(usePublisherStore, ['publisherUrl']),
    ...mapState(useMainStore, [
      'supports',
      'isPaymentButtonsInitialised',
      'initialisedPaymentButtons',
    ]),

    loaderAnimation() {
      return loader;
    },
  },
  methods: {
    ...mapActions(useMainStore, [
      'redirectToView',
      'assessAvailablePaymentButtonsAfterInitialisationAttempted',
    ]),

    /**
     * Progress to hosted fields checkout
     */
    async progressToCheckout() {
      const router = await getRouter();
      this.redirectToView('/shipping', router.push);
    },

    /**
     * Wait a finite amount of time before deciding to conditionally render payment buttons based on whether they successfully initialised in
     * an arbritray but reasonable amount of time. I.e. upon this timeout, then only render buttons which initialised, or rather don't let one failed
     * button prevent any of them from showing.
     */
    startPaymentButtonInitialisationTimer() {
      this.paymentButtonInitialisationTimerId = setTimeout(() => {
        this.assessAvailablePaymentButtonsAfterInitialisationAttempted();
        this.paymentButtonInitialisationTimerExpired = true;
      }, constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT);
    },

    isPaymentButtonInitialisedSuccessfully(flag) {
      return !!this.initialisedPaymentButtons[flag];
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
