import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import router from '@/router/router.js';
import { LottieAnimation } from 'lottie-web-vue';
import App from './App.vue';
import { useMainStore } from '@/stores/MainStore.ts';
import postMessenger, { initInboundPostMessaging } from '@/plugins/postMessenger';
import gtmTracker from '@/helpers/googleTagManager.ts';
import mediaMatchInspector from '@/helpers/mediaMatchInspector';
import packageJson from '../package.json';

const app = createApp(App);

const initSentry = () => {
  if (import.meta.env.VITE_APP_IS_IN_E2E === 'true' || import.meta.env.VITE_APP_ENV === 'local') {
    window.Sentry = {
      captureException: () => {},
    };

    return;
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_APP_ENV || 'unspecified',
  });
};

// See https://shopthru.sentry.io/projects/checkout-fe/getting-started/?product=session-replay&product=performance-monitoring&siblingOption=vue3
initSentry();

app.use(createPinia());
app.use(router);
app.use(postMessenger);
app.use(mediaMatchInspector);

gtmTracker.init(app, { vueRouter: router });

if (import.meta.env.VITE_FRAMELESS_DEV_MODE === 'true') {
  // Stub out postMessage to avoid JS errors in payment libraries trying to look for a parent iFrame.
  window.postMessage = () => {};
} else {
  initInboundPostMessaging();
}

app.component('lottie-animation', LottieAnimation);

app.config.errorHandler = (error, instance, info) => {
  const mainStore = useMainStore();

  console.error('Error boundary reached with error', error);

  gtmTracker.trackJourneyEvent({
    event: 'handled_error_thrown',
  });

  try {
    Sentry.captureException(error);
  } catch (err) {
    console.error('Failed to send Sentry log!', err);
  }

  mainStore.clearXhrTracker();

  mainStore.goToErrorPage({
    error,
    isSkipLogToSentry: true,
  });
};

app.mount('#app');

console.log(
  `Shopthru checkout-frame version ${packageJson.version} running in environment: ${import.meta.env.VITE_APP_ENV}`,
);
