<template>
  <div v-if="isRenderComponent">
    <button
      id="applepay-btn"
      class="apple-pay-button apple-pay-button-black"
      @click="startApplePay"
      :disabled="isLoading"
    ></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { usePublisherStore } from '@/stores/PublisherStore.js';
import { useCartStore } from '@/stores/CartStore.ts';
import gtmTracker from '@/helpers/googleTagManager.ts';

// import { ErrorTypes.ApplePaymentError } from '@/types/errors.types.ts';
import ErrorTypes from '@/helpers/moduleMockWrappers/types/errors.types';

import constants from '@/constants';

export default {
  name: 'BraintreeApplePay',
  data() {
    return {
      isLoading: false,
      actions: null,
      shippingContact: null,
      isRenderComponent: true, // TODO detect error in parent window initialisation and set to false to avoid empty space where button would be
    };
  },
  computed: {
    ...mapState(useMainStore, ['supports', 'currency']),
    ...mapState(usePaymentStore, ['transaction', 'orderId']),
    ...mapState(useShippingStore, ['address', 'shippingMethods']),
    ...mapState(useAdvertiserStore, ['advertiserName']),
    ...mapState(usePublisherStore, ['publisherUrl']),
    ...mapState(useCartStore, ['cart', 'getTotalCartPrice', 'getTotalPaymentPrice']),
  },
  created() {
    window.addEventListener('applePayPostMessageEvent', this.handleApplePayPostMessageEvent);
  },
  unmounted() {
    window.removeEventListener('applePayPostMessageEvent', this.handleApplePayPostMessageEvent);
  },
  mounted() {
    this.initBraintree();
  },
  methods: {
    ...mapActions(useCartStore, ['setShippingMethod']),
    ...mapActions(usePaymentStore, ['setPaymentData', 'placeOrder']),
    ...mapActions(useShippingStore, ['fetchShippingMethods', 'setCustomer', 'setAddress']),
    ...mapActions(useMainStore, ['setPaymentButtonInitialised']),

    sendPostMessageToParentWindow(payload) {
      this.$postMessenger(payload);
    },

    async handleApplePayPostMessageEvent({ detail }) {
      // console.log('applePayPostMessageEvent listener called', detail);

      try {
        if (
          !Object.prototype.hasOwnProperty.call(detail, 'key') ||
          !Object.prototype.hasOwnProperty.call(detail, 'message') ||
          !Object.prototype.hasOwnProperty.call(detail.message, 'error')
        ) {
          throw new Error('Missing expected property in applePayPostMessageEvent!');
        }

        if (detail.message.error) {
          throw new Error(
            `applePayPostMessageEvent received indicating an error in caller: ${detail.message.error}`,
          );
        }
      } catch (err) {
        console.error('Error detected in applePayPostMessageEvent listener!', err);
        throw err; // TODO decide what to do with this
      }

      const { key, message } = detail;

      switch (key) {
        case 'BRAINTREE_CLIENT_INIT_SUCCESS_RESPONSE':
          this.setPaymentButtonInitialised('apple', 'INITIALISED');
          break;
        case 'APPLE_PAY_ON_SHIPPING_CONTACT_SELECTED_RESPONSE':
          const { shippingContact } = message;
          const applePayShippingContactUpdate = {};

          this.shippingContact = shippingContact;

          const address = {
            streetAddress: [''],
            city: this.shippingContact.locality,
            region: this.shippingContact.administrativeArea,
            postalCode: this.shippingContact.postalCode,
            country: this.shippingContact.countryCode.toUpperCase(),
          };

          this.setAddress('billing', address);
          this.setAddress('shipping', address);

          await this.fetchShippingMethods();

          const availableShippingMethods = [];

          (this.shippingMethods || []).forEach((option) => {
            availableShippingMethods.push({
              identifier: option.code,
              label: option.label,
              detail: '',
              amount: option.amount,
            });
          });

          applePayShippingContactUpdate.newShippingMethods = availableShippingMethods;

          // Update Shipping code to selected code
          if (!availableShippingMethods.length) {
            throw new Error('No available shipping methods found!');
          }

          await this.setShippingMethod(availableShippingMethods[0].identifier);

          applePayShippingContactUpdate.newTotal = {
            label: this.advertiserName,
            amount: (this.getTotalPaymentPrice || 0).toString(),
          };

          applePayShippingContactUpdate.newLineItems = [
            {
              type: 'final',
              label: 'Subtotal',
              amount: (this.getTotalCartPrice || 0).toString(),
            },
            {
              type: 'final',
              label: `Shipping (${availableShippingMethods[0].label})`,
              amount: (this.cart.pricing.shippingPrice || 0).toString(),
            },
          ];

          this.sendPostMessageToParentWindow({
            type: 'CHECKOUT_APPLE_PAY_ROUTINE',
            key: 'APPLE_PAY_COMPLETE_SHIPPING_CONTACT_SELECTION',
            message: { applePayShippingContactUpdate },
          });
          break;

        case 'APPLE_PAY_SHIPPING_METHOD_SELECTED_RESPONSE':
          const { shippingMethod } = message;
          const applePayShippingMethodUpdate = {};

          await this.setShippingMethod(shippingMethod.identifier);

          if (this.shippingContact) {
            // Update Total
            applePayShippingMethodUpdate.newTotal = {
              label: this.advertiserName,
              amount: (this.getTotalPaymentPrice || 0).toString(),
            };

            // Update Totals
            applePayShippingMethodUpdate.newLineItems = [
              {
                type: 'final',
                label: 'Subtotal',
                amount: (this.getTotalCartPrice || 0).toString(),
              },
              {
                type: 'final',
                label: `Shipping (${shippingMethod.label})`,
                amount: (this.cart.pricing.shippingPrice || 0).toString(),
              },
            ];

            this.sendPostMessageToParentWindow({
              type: 'CHECKOUT_APPLE_PAY_ROUTINE',
              key: 'APPLE_PAY_COMPLETE_SHIPPING_METHOD_SELECTION',
              message: { applePayShippingMethodUpdate },
            });
          } else {
            this.sendPostMessageToParentWindow({
              type: 'CHECKOUT_APPLE_PAY_ROUTINE',
              key: 'APPLE_PAY_COMPLETE_SHIPPING_METHOD_SELECTION',
              message: { applePayShippingMethodUpdate: {} },
            });
          }
          break;

        case 'APPLE_PAY_ON_PAYMENT_AUTHORISED_SUCCESS':
          this.paymentAuthorized(message);
          break;

        case 'APPLE_PAY_COMPLETE_PAYMENT_SUCCESS_RESPONSE':
          this.completeOrder();
          break;

        default:
          break;
      }
    },

    async initBraintree() {
      try {
        this.sendPostMessageToParentWindow({
          type: 'CHECKOUT_APPLE_PAY_ROUTINE',
          key: 'BRAINTREE_CLIENT_INIT',
          message: {
            transactionToken: this.transaction.token,
          },
        });
      } catch (err) {
        throw new ErrorTypes.ApplePaymentError('Error in initBraintree()!', err);
      }
    },

    /**
     * On click of apple pay button
     */
    async startApplePay() {
      try {
        this.isLoading = true;

        await this.initApplePay();

        this.isLoading = false;
      } catch (err) {
        throw new ErrorTypes.ApplePaymentError('Error in startApplePay()!', err);
      }
    },

    async initApplePay() {
      try {
        this.sendPostMessageToParentWindow({
          type: 'CHECKOUT_APPLE_PAY_ROUTINE',
          key: 'APPLE_PAY_SESSION_INIT',
          message: {
            libVersion: 3,
            createPaymentRequestProps: {
              currencyCode: this.currency.currencyCode,
              countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
              total: {
                label: this.advertiserName,
                amount: this.getTotalPaymentPrice,
              },
              lineItems: [
                {
                  type: 'final',
                  label: 'Subtotal',
                  amount: (this.getTotalCartPrice || 0).toString(),
                },
              ],
              requiredBillingContactFields: ['postalAddress'],
              requiredShippingContactFields: ['phone', 'email', 'postalAddress'],
              shippingMethods: [],
            },
          },
        });
      } catch (err) {
        throw new ErrorTypes.ApplePaymentError('Error in initApplePay()!', err);
      }
    },

    /**
     * Update details and place order
     */
    async paymentAuthorized({ payment, payload }) {
      try {
        const { billingContact, shippingContact } = payment;

        this.handlePaymentAuthorizedPayload({
          billingContact,
          shippingContact,
        });

        this.setPaymentData({
          nonce: payload.nonce,
          method: 'applepay',
        });

        this.sendPostMessageToParentWindow({
          type: 'CHECKOUT_APPLE_PAY_ROUTINE',
          key: 'APPLE_PAY_COMPLETE_PAYMENT_SUCCESS',
        });
      } catch (err) {
        throw new ErrorTypes.ApplePaymentError('Error in paymentAuthorized()!', err);
      }
    },

    completeOrder() {
      try {
        this.placeOrder();

        gtmTracker.doPlaceOrderGtmEvent({
          paymentType: 'ApplePay',
        });
      } catch (err) {
        throw new ErrorTypes.ApplePaymentError('Error in completeOrder()!', err);
      }
    },

    handlePaymentAuthorizedPayload({ shippingContact, billingContact }) {
      try {
        const formattedShippingAddress = {
          streetAddress: shippingContact.addressLines,
          city: shippingContact.locality,
          postalCode: shippingContact.postalCode,
          region: shippingContact.administrativeArea,
          country: shippingContact.countryCode.toUpperCase(),
        };
        const formattedBillingAddress = {
          streetAddress: billingContact.addressLines,
          city: billingContact.locality,
          postalCode: billingContact.postalCode,
          region: billingContact.administrativeArea,
          country: billingContact.countryCode.toUpperCase(),
          email: shippingContact.emailAddress,
        };

        this.setCustomer('shipping', {
          firstName: shippingContact.givenName,
          lastName: shippingContact.familyName,
          phone: shippingContact.phoneNumber,
          email: shippingContact.emailAddress,
        });

        this.setCustomer('billing', {
          firstName: billingContact.givenName,
          lastName: billingContact.familyName,
        });

        this.setAddress('shipping', formattedShippingAddress);
        this.setAddress('billing', formattedBillingAddress);
      } catch (err) {
        throw new ErrorTypes.ApplePaymentError('Error in handlePaymentAuthorizedPayload()!', err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
