type ShopThruPostMessageProps = {
  type: string;
  key: string;
  message?: string;
};

class ShopThruPostMessage {
  private type: string;
  private key: string;
  private message?: string;

  constructor(msg: ShopThruPostMessageProps) {
    this.type = msg.type;
    this.key = msg.key;
    this.message = msg.message;
  }

  getMessage(): ShopThruPostMessageProps {
    return {
      type: this.type,
      key: this.key,
      message: this.message,
    };
  }
}

type DictionaryMessage = {
  key: string;
  description?: string;
};

const unexpectedMessageTypesWhitelist = [
  'webpackOk',
  'credentials-library',
  'CHECKOUT_JOURNEY_LOADED',
];

class UnexpectedPostMessage extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'UnexpectedPostMessage';
  }
}

export const messageDictionary: Record<string, Record<string, DictionaryMessage>> = {
  CHECKOUT_APPLE_PAY_ROUTINE: {
    BRAINTREE_CLIENT_INIT_SUCCESS_RESPONSE: {
      key: 'BRAINTREE_CLIENT_INIT_SUCCESS_RESPONSE',
      description: 'Response from Braintree Client instantiation in parent.',
    },
    APPLE_PAY_ON_VALIDATE_MERCHANT_RESPONSE: {
      key: 'APPLE_PAY_ON_VALIDATE_MERCHANT_RESPONSE',
      description: 'Response from Apple Pay merchant validation in parent.',
    },
    APPLE_PAY_ON_SHIPPING_CONTACT_SELECTED_RESPONSE: {
      key: 'APPLE_PAY_ON_SHIPPING_CONTACT_SELECTED_RESPONSE',
      description: 'Response from Apple Pay shipping contact selected in parent.',
    },
    APPLE_PAY_SHIPPING_METHOD_SELECTED_RESPONSE: {
      key: 'APPLE_PAY_SHIPPING_METHOD_SELECTED_RESPONSE',
      description: 'Response from Apple Pay shipping method selection in parent.',
    },
    APPLE_PAY_ON_PAYMENT_AUTHORISED_SUCCESS: {
      key: 'APPLE_PAY_ON_PAYMENT_AUTHORISED_SUCCESS',
      description: 'Success response from Apple Pay payment authorised in parent.',
    },
    APPLE_PAY_COMPLETE_PAYMENT_SUCCESS_RESPONSE: {
      key: 'APPLE_PAY_COMPLETE_PAYMENT_SUCCESS_RESPONSE',
      description: 'Response from Apple Pay payment completion success in parent.',
    },
  },
};

const expectedOrigin = ''; // Get from Parent domain state prop;

export const handleInboundPostMessage = (e) => {
  const { data } = e;
  const { key, message, type } = data;
  let matchedMessage: ShopThruPostMessageProps;

  if (false) {
    // TODO expectedOrigin !== e.origin) {
    return;
  }

  if (typeof e.data !== 'object') {
    return;
  }

  try {
    matchedMessage = messageDictionary[type][key];

    if (!matchedMessage) {
      throw new Error();
    }
  } catch (err) {
    if (
      !type ||
      unexpectedMessageTypesWhitelist.some((listItem: string) => type.includes(listItem))
    ) {
      return;
    }

    const msg = `Unexpected message with data: ${JSON.stringify(e.data)}`;

    console.error(msg);

    if (import.meta.env.VITE_APP_ENV !== 'local' || import.meta.env.VITE_APP_ENV !== 'dev') {
      // Noisy in logs when working in checkout page directly without parent window.
      throw new UnexpectedPostMessage(msg);
    }
  }

  switch (type) {
    case 'CHECKOUT_APPLE_PAY_ROUTINE':
      window.dispatchEvent(
        new CustomEvent('applePayPostMessageEvent', { detail: { key, message } }),
      );
      break;

    default:
      break;
  }
};

// Listen for inbound postMessage events coming from the Embed script in the parent window
export const initInboundPostMessaging = () => {
  window.addEventListener('message', handleInboundPostMessage);
};

export default {
  // Inject an outbound postMessage helper to Vue
  install: (app: ToDo) => {
    app.config.globalProperties.$postMessenger = (msg: ShopThruPostMessageProps) => {
      try {
        parent.window.postMessage(new ShopThruPostMessage(msg).getMessage(), '*');
      } catch (err) {
        console.error('Failed to send post message to parent window!', err);
      }
    };
  },
};
